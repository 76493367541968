import "slick-carousel";

import "slick-carousel/slick/slick.css";

$(document).ready(() => {
  const mainSlide = $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    appendDots: $("#homeDots"),
    autoplay: true,
    autoplaySpeed: 4000,
    asNavFor: $("#slideProductsList"),
    pauseOnHover: false,
    pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          adaptiveHeight: true
        }
      }
    ]
  });

  const secondSlide = $("#slideProductsList").slick({
    asNavFor: $("#homeSlider"),
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    pauseOnHover: true
  });

  $("#slideProductsList").on("mouseenter", () => {
    mainSlide.slick("slickPause");
  });

  $("#slideProductsList").on("mouseleave", () => {
    mainSlide.slick("slickPlay");
  });

  // slider products open/close
  $("#slideProductsBtn").on("click", function() {
    $("#slideProductsBtnOpen, #slideProductsBtnClose").toggle();
    $("#slideProducts").toggleClass("slider__products--open");
  });
});
